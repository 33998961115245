
  import LzButton from "@/components/Button.vue";
  import LzModal from "@/components/Modal.vue";
  import { Component, Vue, Watch } from "vue-property-decorator";
  import { namespace } from "vuex-class";
  import { apiMembers } from "../api";

  const auth = namespace("auth");

  const store = namespace("auth");

  @Component({
    components: { LzButton, LzModal }
  })
  export default class Signup extends Vue {
    signupForm = {
      firstName: "",
      lastName: "",
      companyName: "",
      mobilePhone: "",
      cif: "",
      email: "",
      gender: "",
      dni: "",
      organization: "",
      password: ""
    };

    hasORG = false;

    @Watch("signupForm.organization")
    onHasYBSChange(newVal: string, oldVal: string) {
      this.hasORG =
        newVal !== this.$t("auth.signup.form.organizations.none")
          ? true
          : false;
    }

    genderOptions = {
      man: this.$t("auth.signup.form.gender.man"),
      woman: this.$t("auth.signup.form.gender.woman"),
      other: this.$t("auth.signup.form.gender.other")
    };

    organizationOptions = [
      "Asociación Arrabal",
      "Afedes",
      "F.Autoocupación",
      "Aprofem",
      "F.Ronsel",
      "Gaztenpresa",
      this.$t("auth.signup.form.organizations.none")
    ];

    loadingPostMember = false;

    @store.Action
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateData!: (payload: any) => void;

    @store.Action
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateFeatures!: (payload: any) => void;

    @auth.State("ioCashUserId")
    public ioCashUserId!: string;

    onSignupSubmit() {
      this.loadingPostMember = true;

      if (
        this.signupForm.organization === "None" ||
        this.signupForm.organization === "Ninguno"
      ) {
        this.signupForm.organization = "none";
      }

      apiMembers
        .create({
          firstName: this.signupForm.firstName,
          lastName: this.signupForm.lastName,
          companyName: this.signupForm.companyName,
          mobilePhone: this.signupForm.mobilePhone,
          cif: this.signupForm.cif,
          email: this.signupForm.email,
          gender: this.signupForm.gender,
          dni: this.signupForm.dni,
          organization: this.signupForm.organization,
          password: this.signupForm.password,
          tools: []
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => {
          this.$notify({
            type: "success",
            text: this.$tc("auth.signup.notifications.success")
          });
          const { token, data } = response;
          if (token) {
            localStorage.setItem("accessToken", token);
            localStorage.setItem("auth", JSON.stringify(data));
            this.updateData(data);
            //this.updateFeatures(data.features);
            this.$router.push({ name: "Onboarding" });
          } else {
            throw new Error("No token included in the response");
          }
        })
        .catch(err => {
          let error = "";
          switch (err.response.data.message) {
            case "EXISTS_EMAIL":
              error = "auth.signup.notifications.emailExists";
              break;
            case "EXISTS_COMPANY":
              error = "auth.signup.notifications.companyExists";
              break;
            case "DUPLICATE_CIF":
              error = "auth.signup.notifications.duplicateCIF";
              break;
            default:
              error = "common.error.generic";
              break;
          }
          this.$notify({
            type: "error",
            text: this.$tc(error)
          });
        })
        .finally(() => {
          this.loadingPostMember = false;
        });
    }
  }
